import React from "react";
import { graphql } from "gatsby";
import { Grid, Image } from "semantic-ui-react";
import Helmet from "react-helmet";

import {
  PageLayout,
  HeroElement,
  ImageCollection,
  MenuFilesDisplay,
} from "@fishrmn/fishrmn-components";

import Layout from "../components/Layout";
import { getPageHeroImages } from "../utils/images";

export default class extends React.PureComponent {
  render() {
    const data = this.props.data.allFisherman.edges[0].node;
    const pageData = this.props.data.fishermanWebsitePage;
    const pageComponents = (pageData && pageData.components) || [];

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | About Us</title>
          <meta name="description" content={pageData.description} />
        </Helmet>
        <PageLayout
          hero={
            <HeroElement
              header={"About Us"}
              tagline={""}
              images={getPageHeroImages(pageComponents)}
              showMap={false}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
        >
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={14}>
             <p>
             Founded in 2014, Ophelia’s Blue Vine Farm strives to bring fresh herbs to the Kansas City metro.
             </p>
             <p>Our Grandmother, Miss Ophelia, is the inspiration behind everything we do. As a single Black mother, she raised 6 children and put them through college.
              Two of which went on to play in the NFL.</p>

              <p>It’s safe to say she never settled for second best. That’s why we don’t either.
We never let a pack of herbs leave our greenhouse if we don’t think Miss Ophelia would call it excellent.</p>

              <p>We are proud to call Kansas City’s 18th &amp; Vine District home. Our urban greenhouse is nestled among historic spots that helped bring jazz music to the world. We take pride in our neighborhood and want to build up the community that surrounds it.</p>
              <p>We continually strive to better the world and our neighborhood by committing to sustainable farming practices and community pride initiatives. We partner with other local businesses to use food waste for biofuel to heat our greenhouse or compost it into healthy fertilizer.</p>
            
              <p>Our work is for you, Kansas City. We believe everyone should have access to fresh, great tasting food and know how it grows. Ophelia’s Blue Vine Farm strives to live up to our namesake every day. We hope you’ll give us the chance to show you the excellence behind our name.</p>
              <p>Love,</p>
              <p>Ophelia's</p>
            </Grid.Column>



            <Grid.Column width={16}>
              <ImageCollection
                as={"gallery"}
                centered={true}
                cutUnevenRows={false}
                disable={"none"}
                header={"Gallery"}
                images={[
                  "https://fisherman.gumlet.io/public/opheliasbluevine/ourstory1.jpg",
                  "https://fisherman.gumlet.io/public/opheliasbluevine/ourstory2.jpg",
                  "https://fisherman.gumlet.io/public/opheliasbluevine/ourstory3.jpg",
                  "https://fisherman.gumlet.io/public/opheliasbluevine/ourstory4.jpg",
                  "https://fisherman.gumlet.io/public/opheliasbluevine/ourstory5.jpg",
                ]}
                numberOfRowDisplayItems={3}
              />
            </Grid.Column>
          </Grid>

          <Grid
            stackable
            className="component-section-container rm-padding-top"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <MenuFilesDisplay
                files={[
                  {
                    filename: "Our Story",
                    url: "https://fisherman.gumlet.io/public/opheliasbluevine/Our_Story.pdf",
                  },
                ]}
              />
            </Grid.Column>
            <Grid.Column width={14}>
              <Image src="https://fisherman.gumlet.io/public/opheliasbluevine/renditionDownload_pqXnG9M.jpeg" 
              centered
              size="large"/>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
          aboutMarkdown
          phone
          email
          address {
            street
            city
            state
            zip
          }
        }
      }
    }
    fishermanWebsitePage(title: { eq: "Jobs" }) {
      description
      components {
        fastId
        order
        files {
          file
        }
      }
    }
  }
`;
